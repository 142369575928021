import { APP_INITIALIZER, NgModule } from '@angular/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import { environment } from 'src/environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { SharedModule } from './shared';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ErrorCatchingInterceptor } from './core/services/error-catching.interceptor';
import { LoaderInterceptor } from './core/services/loader.interceptor';
import { PreviewComponent } from './preview/preview.component';

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        realm: environment.keycloakConfig.realm,
        url: environment.keycloakConfig.url,
        clientId: environment.keycloakConfig.clientId
      },
      initOptions: {
        onLoad: 'check-sso',
        // silentCheckSsoRedirectUri:
        //   window.location.origin + '/assets/silent-check-sso.html',
        checkLoginIframe: false
        // checkLoginIframeInterval: 25
      },
      loadUserProfileAtStartUp: true,
      bearerExcludedUrls: ['/assets/*'],
      shouldAddToken(request) {
        return !request.url.includes('storage.googleapis');
      }
    });
}
@NgModule({
  declarations: [AppComponent, UnauthorizedComponent, PreviewComponent],
  imports: [
    CoreModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    KeycloakAngularModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatIconModule
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
